.title-number {
    padding: 0 15px;
    font-size: 14px;
    text-align: center;
}
.bold{
    font-weight: bold;
    font-size: 1.2rem;
}

.icon-dashboard span{
    vertical-align: 0em;
}

.dashboard-little-card {
    background: var(--foreground);  
    width: 130px;
    max-height: 150px;
    border-radius: 20px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 10px;
    margin-bottom: 10px;
    border: solid 2px var(--secondary);
}

.dashboard-little-card p {
    color:var(--primary);
}

.dashboard-little-card .icon-dashboard {
    color: var(--foreground);
    border-radius: var(--border-radius-lg);
    padding: 3px 11px 5px;
    font-size: 19px;
}

@media (max-width: 1035px) {
    .dashboard-little-card {
        width: 100%;
        max-height: 60px;
        flex-direction: row;
        justify-content: flex-start;
    }
    .title-number {
        padding: 0 15px;
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;
        line-height: 1px;
    }
    .bold{
        font-size: 0.9rem;
    }
}