@import "../layout/typography";

.ant-modal-mask {
  //z-index: 1002;
}

.ant-modal-wrap {
  //z-index: 1003;
}

.ant-modal-content {
  background-color: var(--background);
}

.ant-modal-header {
  border-bottom: none;
  background-color: var(--background);
  padding-bottom: 0;
  .ant-modal-title {
    @extend .display-4;
  }
}
