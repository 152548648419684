.nav-container {
  .ant-select {
    &:hover {
      background: rgba(var(--light-text-rgb), 0.1);
      box-shadow: 0 4px 10px rgb(0 0 0 / 3%) !important;
      color: var(--light-text) !important;
    }

    .ant-select-selector {
      color: var(--light-text) !important;
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }

    .ant-select-arrow {
      color: var(--light-text) !important;
    }

    &.ant-select-open {
      .ant-select-selection-item {
        color: var(--light-text) !important;
        box-shadow: none !important;
      }
    }

    .ant-select-item-option {
      padding: 0.5rem 1.5rem !important;
    }
  }

  .menu-container {
    .ant-menu {
      padding: 0;
      background: none !important;
      color: var(--light-text) !important;
      font-family: var(--font-heading) !important;
      font-size: 13px;

      /*.ant-menu-submenu:first-of-type {
        .ant-menu-submenu-title {
          background: rgba(var(--light-text-rgb), 0.1) !important;
          box-shadow: 0 4px 10px rgb(0 0 0 / 3%) !important;
          border-radius: 24px;
          width: 100%;
          transition: color var(--transition-time);
        }
      }*/

      .ant-menu-item-icon {
        font-size: 18px;
      }

      &.ant-menu-inline {
        border: none;
      }

      .ant-menu-item-selected {
        background: rgba(var(--light-text-rgb), 0.1);
        box-shadow: 0 4px 10px rgb(0 0 0 / 3%) !important;
        color: var(--light-text) !important;
        border: none;
      }

      .ant-menu-submenu-active,
      .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          background: rgba(var(--light-text-rgb), 0.1) !important;
          box-shadow: 0 4px 10px rgb(0 0 0 / 3%) !important;
          border-radius: var(--border-radius-md);
          width: 100%;
          transition: color var(--transition-time);
        }
      }

      .ant-menu-item-active,
      .ant-menu-item-selected {
        color: var(--light-text) !important;
        background: rgba(var(--light-text-rgb), 0.1) !important;
        box-shadow: 0 4px 10px rgb(0 0 0 / 3%) !important;
        border-radius: var(--border-radius-md);
        //border-radius: var(--border-radius-lg);
        width: 100%;
        transition: color var(--transition-time);
        &:first-of-type {
        }
      }

      .ant-menu-submenu-title {
        color: var(--light-text) !important;

        .ant-menu-submenu-arrow {
          color: var(--light-text) !important;
        }
      }
      //

      .ant-menu-inline .ant-menu-item::after{
        border: none;
      }
    }
  }

  .user-container {
    position: relative;
    .user-menu {
      top: 90px;
      left: -85px;
    }
  }

  .ant-menu-vertical {
    border-right: none;
  }
}

/* html[data-menu-animate="hidden"] .ant-menu-sub{
  display: none !important;
} */

html[data-placement="vertical"][data-behaviour="unpinned"][data-menu-animate="hidden"]
  .ant-menu-submenu-arrow,
html[data-placement="vertical"][data-behaviour="unpinned"][data-menu-animate="hidden"]
  .ant-menu-sub {
  display: none;
}

html[data-placement=":vertical"] {
  color: #1890ff !important;
}
