@import "../themes/light.odc";

.ant-btn-primary:hover {
  color: var(--primary);
  border-color: var(--primary);
  background: var(--primary);
}

.ant-btn-dangerous.ant-btn-primary:hover {
  color: var(--danger) !important;
  border-color: var(--danger);
  background: var(--danger);
}

button.ant-btn.btn.btn-outline-danger:hover {
  background: var(--danger) !important;
}

button.ant-btn.btn.btn-outline-primary:hover {
  background: var(--primary) !important;
}

.ant-btn-primary:hover {
    color: var(--primary) !important;
    border-color: var(--primary);
    background: var(--primary);
    border: 1px solid;
}

.btn-outline-info:hover {
    background-color: var(--info) !important;
  color: var(--primary) !important;
  border-color: var(--primary);
  background: var(--primary);
  border: 1px solid;
}

.ant-btn-link {
  color: var(--primary);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

.ant-btn-link:hover {
  color: var(--secondary);
}

.ant-btn-dashed:hover, .ant-btn-dashed:focus {
  color: var(--primary);
  border-color: var(--primary);
  background: #fff;
}