.ant-form-item {
  .ant-form-item-label {
    min-width: 100%;
    margin-right: 20px;
    text-align: left;
    white-space: initial;
    width: 15%;
    label {
      color: var(--alternate);
    }
    label::after {
      display: none;
    }
  }
  .ant-form-item-control {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        .ant-input {
          font-size: 1em;
          height: auto;
          min-height: var(--input-height);
          color: var(--body);
          box-shadow: initial !important;
          background-color: var(--foreground);
          border-color: var(--separator);
          border-radius: var(--border-radius-md);
          filter: none;
          transition: border-color 0.15s ease-in-out;
        }
      }
    }
  }
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: var(--danger) !important;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
