.ant-pagination {
  .ant-pagination-item {
    color: var(--primary);
    border-radius: var(--border-radius-md) !important;
    border-color: transparent !important;

    &:hover {
      background: var(--primary);
      color: var(--light-text) !important;  
    }
  }

  .ant-pagination-item-link {
    color: var(--primary);
    border-radius: var(--border-radius-md) !important;
    border-color: transparent !important;

    &:hover {
      background: var(--primary);
      color: var(--light-text) !important;
    }
  }

  .ant-pagination-disabled {
    opacity: 0.5;
    color: var(--primary);
    background: var(--foreground);
    box-shadow: initial !important;
  }

  .ant-pagination-item-active {
    background: var(--primary);
    color: var(--light-text) !important;
    box-shadow: initial !important;
    &:hover {
      background: var(--primary);
      color: var(--light-text) !important;
    }
  }
}
