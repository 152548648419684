.fixed-background
 {
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

a.backHome {
  transition: color var(--transition-time-short);
  text-decoration: initial;
  color: #00bc64 !important;
  outline: initial !important;
  &:hover,
  &:active {
    text-decoration: initial;
    color: #00a6b0 !important;
  }
}


.btn-close:after {
  content: none !important;
}

.ant-btn {
  &:hover {
    background-color: transparent !important;
  }
}
