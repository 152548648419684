@import "../themes/light.odc";

.ant-picker-focused {
  border-color: var(--separator);
  box-shadow: 0 0 0 2px transparent;
}

.ant-picker:hover {
  border-color: var(--primary);
}
.ant-picker {
    border-radius: var(--border-radius-md);
  .ant-picker-active-bar {
    background: var(--primary);
  }
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: var(--primary);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: var(--primary-shadow);
}

.ant-picker-header button:hover {
  color: var(--primary);
}
