.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: var(--tertiary-darker) !important;
}

.ant-tabs-ink-bar{
    background: var(--tertiary-darker) !important;
}

.ant-tabs-tab:hover{
    color: var(--tertiary-darker) !important;
}

.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 0 0;
}