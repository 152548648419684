@import "../themes/light.odc";

.ant-switch-checked {
  background-color: var(--primary);
  .ant-switch-loading-icon {
    color: var(--primary);
  }
}
.ant-spin-dot-item {
  background-color: var(--primary);
}