@import "../themes/light.odc";
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--separator);
  border-radius: var(--border-radius-md);
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: var(--separator);
  box-shadow: 0 0 0 2px transparent;
}

