@import "../bootstrap/form";

.ant-input {
  //@extend .form-control;
  width: 100%;
  &::placeholder {
    color: var(--muted);
  }
}

.ant-input-affix-wrapper {
  padding: inherit;
  border: none;
  color: inherit;
  background-color: inherit;
  box-shadow: inherit;

  &:hover {
    z-index: inherit !important;
  }

  & > input.ant-input {
    @extend .form-control;
  }
}

.ant-input-affix-wrapper-focused {
  z-index: inherit !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.ant-form-item-control-input {
  //@extend .form-control;
  width: 100%;
}

.ant-input-password {
  //@extend .form-control;

  .ant-input-suffix {
    position: absolute !important;
    top: 15px !important;
    right: -20px !important;
  }
}

.ant-input:focus,
.ant-input-focused {
  outline: 0 !important;
  box-shadow: none !important;
}

//AJUSTE A HEIGHT DEL LABEL

.ant-form-item-label > label {
  height: auto;
}
