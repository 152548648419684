.tableView {
  .ant-table {
    background-color: transparent !important;
    background: none;

    .ant-table-row {
      .ant-table-cell:first-child {
        overflow: hidden;
      }
    }

    .ant-table-cell {
      text-align: center;
    }
  }

  .ant-pagination-options .ant-select .ant-select-selector, .ant-select-arrow{
    color: #00bc64;
  }
}
