@import "../themes/light.odc";
.ant-input-group-addon {
    border-radius: 5px;
}
.ant-input:hover {
    border-color: var(--primary);
}
.ant-input-group-addon .ant-select-open .ant-select-selector, .ant-input-group-addon .ant-select-focused .ant-select-selector {
    color: var(--primary);
}

.ant-input {
    border-radius: 5px;
}