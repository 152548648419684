
/* SUCCESS */
.ant-notification-notice.ant-notification-notice-success{
    color: white;
    background: #6fc944;
    box-shadow: 0 0 15px #6fc944;    
    .ant-notification-notice-with-icon .ant-notification-notice-message, 
    .anticon.ant-notification-notice-icon-success,
    .ant-notification-close-x{ 
        color: white !important;
    }
}

/* Info */
.ant-notification-notice.ant-notification-notice-info{
    color: white;
    background: #1890ff;
    box-shadow: 0 0 15px #1890ff;    
    .ant-notification-notice-with-icon .ant-notification-notice-message, 
    .anticon.ant-notification-notice-icon-info,
    .ant-notification-close-x{ 
        color: white !important;
    }
}

/* Warning */
.ant-notification-notice.ant-notification-notice-warning{
    color: white;
    background: #faad14;
    box-shadow: 0 0 15px #faad14;    
    .ant-notification-notice-with-icon .ant-notification-notice-message, 
    .anticon.ant-notification-notice-icon-warning,
    .ant-notification-close-x{ 
        color: white !important;
    }
}


/* Error */
.ant-notification-notice.ant-notification-notice-error{
    color: white;
    background: #ff4d4f;
    box-shadow: 0 0 15px #ff4d4f;    
    .ant-notification-notice-with-icon .ant-notification-notice-message, 
    .anticon.ant-notification-notice-icon-error,
    .ant-notification-close-x{ 
        color: white !important;
    }
} 


/*--------- PROGRESSBARS -------------- */           

.ant-progress:not(.ant-progress-status-exception, .ant-progress-status-success) {           
    color: #1890ffBB;
    .ant-progress-text{
        color: inherit;
        font-weight: 300;
    }
}




 
