.card-item{
    background: var(--foreground);
    border-radius: var(--border-radius-lg);
    padding: 20px;
    box-shadow: rgb(100 100 111 / 20%) 0px 3px 9px 0px;
    display: flex;
    width: 49%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 10px 0;
    cursor: pointer;
}

.card-item-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card-item-info {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr;  
    gap: 0px 10px; 
    grid-template-areas: ". ."; 
    grid-area: 2 / 1 / 3 / 2; 
}

.card-item-icon {
    font-size: 18px;
    margin-right: 5px;
    color: var(--alternate)
}

.info{
    display: flex;
    align-items: center;
}

.info h5{
    margin-left: 2px;
    margin-bottom: 0px;
    width: 25%;
}

@media (max-width: 800px) {
    .card-item {
        width: 90%;
    }
}

@media (max-width: 1600px) {
    .card-item-info {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}