.side-panel {
	transition: transform 300ms;
	height: 100%;
	position: fixed;
	background-color: white;
	z-index: 999;
	border-left: 1px #ddd solid;
	background: #fff;
	box-shadow: 1px 6px 0px 1px silver;
}

.side-panel-content {
	padding: 24px;
	height: 100%;
	position: relative;
	overflow-y: auto;
}

.side-panel-button {
	cursor: pointer;
	background: white;
	padding: 7px 2px;
	border-radius: 0.2rem;
	box-shadow: -4px 2px 4px rgba(0, 0, 0, 0.04);
	font-size: 15px;
	line-height: 0;
}

.side-panel-header {
	width: 100%;
}

.side-panel-body {
	height: 100%;
	overflow-x: scroll;
}

.side-panel-footer {
	position: fixed;
	bottom: 40px;
	height: 40px;
	min-width: 500px;
	background-color: white;
	padding: 25px 0;
}

/* Right */
.shown-right {
	transform: translateX(0) !important;
}

.side-panel-right {
	width: 350px;
	transform: translateX(350px);
	float: right;
	right: 0;
	top: 0;
}

.side-panel-button-right {
	cursor: pointer;
	position: absolute;
	left: -36px;
	top: 45px;
	padding: 10px;
}

/* Left */
.shown-left {
	transform: translateX(350px) !important;
}

.side-panel-left {
	width: 350px;
	transform: translateX(0px);
	float: left;
	left: -284px;
	top: 50px;
}

.side-panel-button-left {
	cursor: pointer;
	position: absolute;
	right: -30px;
	top: 45px;
}

/* Top */

/* Bottom */

.overlay-panel {
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-tap-highlight-color: transparent;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 2;
}

.side-panel::-webkit-scrollbar {
	display: none; // Safari and Chrome
}

.side-panel {
	-ms-overflow-style: none; // Internet Explorer 10+
	scrollbar-width: none; // Firefox
}

.side-panel-body::-webkit-scrollbar {
	display: none; // Safari and Chrome
}

.side-panel-body {
	-ms-overflow-style: none; // Internet Explorer 10+
	scrollbar-width: none; // Firefox
}
