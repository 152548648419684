.ExcelTable2007 {
	border: 1px solid #B0CBEF;
	border-width: 1px 0px 0px 1px;
	font-size: 11pt;
	font-family: Calibri;
	font-weight: 100;
	border-spacing: 0px;
	border-collapse: collapse;
	padding: 10px;
    width: 100%;
	display: block;
    overflow: scroll;
    max-width: 100%;
    max-height: 400px;
    padding: 0;
}

.ExcelTable2007::-webkit-scrollbar{
	width: 10px;
    height: 10px;
    background-color: #EEE;
    opacity: 50%;
}


.ExcelTable2007::-webkit-scrollbar-thumb{
	background-color: var(--primary);
	border-radius: 10px;
}

.ExcelTable2007 TH {
	background-image: #B0CBEF;
	background-repeat: repeat-x; 
	font-weight: bold;
	font-size: 14px;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
	height: 17px;
	line-height: 17px;
}

.ExcelTable2007 TD {
	border: 0px;
	background-color: white;
	padding: 0px 4px 0px 2px;
	border: 1px solid #D0D7E5;
	border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD B {
	border: 0px;
	background-color: white;
	font-weight: bold;
}

.ExcelTable2007 TD.heading {
	background-color: #E4ECF7;
	text-align: center;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
	font-weight: bold;
}

.ExcelTable2007 TH.heading {
	background-image: #B0CBEF;
	background-repeat: none;
}

TH {
	text-align: center;
}

.percentLoadingExcel {
	width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

tr.redBg td{
	background-color: #ffe4e4  !important;
}

tr.yellowBg td{
	background-color: #fffee4  !important;
}

.tablaImportUsers td:nth-of-type(6) {
    min-width: 200px;
}
.tablaImportUsers td:nth-of-type(5) {
    min-width: 100px;
}
.tablaImportUsers td:nth-of-type(4) {
    min-width: 100px;
}

