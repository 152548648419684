.image-list-container {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  width: 100%;
}

.image-list {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.image-list img {
  width: 100px;
  height: auto;
}
.image-list-container::-webkit-scrollbar {
  height: 4px;
}

.image-list-container::-webkit-scrollbar-thumb {
  background-color: #00bc643f; /* Color del pulgar de la barra de desplazamiento */
  border-radius: 2px; /* Radio de borde del pulgar */
}

.image-list-container::-webkit-scrollbar-thumb:hover {
  background-color: #00bc64; /* Color del pulgar al pasar el cursor sobre él */
}

.image-list-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color de fondo de la barra de desplazamiento */
  border-radius: 2px; /* Radio de borde de la barra de desplazamiento */
}