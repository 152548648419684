.ant-alert {
  border: initial;
  border-radius: var(--border-radius-md);
}

.ant-alert-error {
  border: none;
  color: var(--danger) !important;
  background-color: rgba(var(--danger-rgb), 0.15);
}

.ant-alert-message {
    color: inherit;
}


// Primary Alert 

.ant-alert-primary {
  background-color: rgba(var(--primary-rgb), 0.15);

}

.ant-alert-primary .ant-alert-content .ant-alert-message,
.ant-alert-primary .ant-alert-content .ant-alert-description{
color: var(--primary) !important;
}

// Secundary Alert 

.ant-alert-secundary {
  background-color: rgba(var(--secondary-rgb), 0.15);

}

.ant-alert-secundary .ant-alert-content .ant-alert-message,
.ant-alert-secundary .ant-alert-content .ant-alert-description{
  color: var(--secondary)!important;
}

// Success Alert  

.ant-alert-success {
  background-color: rgba(var(--success-rgb), 0.15);

}

.ant-alert-success .ant-alert-content .ant-alert-message,
.ant-alert-success .ant-alert-content .ant-alert-description{
  color: var(--success)!important;
}


// Danger Alert 

.ant-alert-danger {
  background-color: rgba(var(--danger-rgb), 0.15);

}

.ant-alert-danger .ant-alert-content .ant-alert-message,
.ant-alert-danger .ant-alert-content .ant-alert-description{
  color: var(--danger)!important;
}

// Warning Alert 

.ant-alert-warning {
  background-color: rgba(var(--warning-rgb), 0.15);

}

.ant-alert-warning .ant-alert-content .ant-alert-message,
.ant-alert-warning .ant-alert-content .ant-alert-description{
  color: var(--warning)!important;
}

// Info Alert 

.ant-alert-info {
  background-color: rgba(var(--info-rgb), 0.15);

}

.ant-alert-info .ant-alert-content .ant-alert-message,
.ant-alert-info .ant-alert-content .ant-alert-description{
  color: var(--info)!important;
}

// Light Alert 

.ant-alert-light {
  background-color: rgba(var(--dark-rgb), 0.05);

}

.ant-alert-light .ant-alert-content .ant-alert-message,
.ant-alert-light .ant-alert-content .ant-alert-description{
  color: var(--alternate)!important;
}


// Dark Alert 

.ant-alert-dark {
  background-color: rgba(var(--dark-rgb), 0.15);

}

.ant-alert-dark  .ant-alert-content .ant-alert-message,
.ant-alert-dark  .ant-alert-content .ant-alert-description{
  color: var(--alternate)!important;
}