.mapSpinning, 
.mapSpinning .ant-spin-container{
    height: 100%;
}

.dashboard-card-content{
    background: white;
    border-radius: var(--border-radius-lg);
    padding: 20px 15px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin: 0 6px;
}

.little-cards-distribution{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap:  wrap;
}

.dashboard-content-list-distribution {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap:  wrap;
}

.dashboard-list{
    overflow-y: scroll;
    max-height: 400px; 
    min-height: 400px; 
    height: initial !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    background: var(--foreground);
    border-radius: var(--border-radius-lg);
    padding: 0px 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: inherit;
    align-items: center;
}

.dashboard-list::-webkit-scrollbar{
    overflow-y: scroll;
    overflow-x: none;
    max-height: 200px;  
    width: 5px;
    height: 50%;
    background: transparent;
    padding-top: 10px;
}

.dashboard-list::-webkit-scrollbar-thumb{
    background: var(--primary);
    border-radius: var(--border-radius-lg);
    padding-top: 10px;
    
}


@media (max-width: 800px) {
    .dashboard-content-list-distribution {
        flex-direction: column;
    }
    .dashboard-card-content {
        margin: 10px 0;
    }
}


iframe{
    border-radius: var(--border-radius-lg);
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.ant-alert-message{
    font-size: 11px;
}

.ant-progress{
    margin-top: 7px !important;
    padding: 0 10% !important;
}

.ant-progress-bg{
    background-color: #00a8ad!important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary)!important;
    border-color: var(--primary)!important;
    
}

.ant-alert{
    padding: 5px !important;
}

.mg-t{
    margin-top: 30px !important;
}

.text-gray{
    color: gray !important;
}