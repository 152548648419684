/*
*
* Dark Blue
*
* Theme variables.
*
*/

html[data-color='dark-odc'] {
  --primary: #1b98d0;
  --secondary: #61c6d8;
  --tertiary: #54cdb4;
  --quaternary: #4d7fdb;
  --primary-rgb: 27, 152, 208;
  --secondary-rgb: 97, 198, 216;
  --tertiary-rgb: 84, 205, 180;
  --quaternary-rgb: 77, 127, 219;

  --primary-darker: #1989bb;
  --secondary-darker: #56b1c1;
  --tertiary-darker: #46af99;
  --quaternary-darker: #426ebf;

  --body: #c1c1c1;
  --alternate: #999999;
  --muted: #727272;
  --separator: #474747;
  --separator-light: #2e2e2e;
  --body-rgb: 193, 193, 193;
  --alternate-rgb: 153, 153, 153;
  --muted-rgb: 114, 114, 114;
  --separator-rgb: 71, 71, 71;
  --separator-light-rgb: 46, 46, 46;

  --background: #1d1d1d;
  --foreground: #242424;
  --background-rgb: 29, 29, 29;
  --foreground-rgb: 36, 36, 36;

  --background-theme: #242424;
  --background-light: #292929;

  --gradient-1: #1b98d0;
  --gradient-2: #1d9dd7;
  --gradient-3: #1ea5e1;

  --gradient-1-lighter: #1ea5e1;
  --gradient-1-darker: #1a90c5;

  --light-text: #f0f0f0;
  --dark-text: #191c1f;
  --light-text-darker: #e9e9e9;
  --dark-text-darker: #08090a;

  --light-text-rgb: 240, 240, 240;
  --dark-text-rgb: 25, 28, 31;

  --danger: #b62836;
  --info: #298a99;
  --warning: #ebb71a;
  --success: #418b38;
  --light: #dadada;
  --dark: #4e4e4e;
  --danger-darker: #771a23;
  --info-darker: #19545d;
  --warning-darker: #aa830f;
  --success-darker: #285422;
  --light-darker: #c9c9c9;
  --dark-darker: #282828;
  --body-darker: #a0a0a0;
  --alternate-darker: #6e6e6e;
  --muted-darker: #4e4e4e;
  --separator-darker: #353535;

  --danger-rgb: 182, 40, 54;
  --info-rgb: 41, 138, 153;
  --warning-rgb: 235, 183, 26;
  --success-rgb: 65, 139, 56;
  --light-rgb: 218, 218, 218;
  --dark-rgb: 78, 78, 78;

  --menu-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  --menu-shadow-navcolor: 0px 3px 10px rgba(0, 0, 0, 0.4);

  --background-navcolor-light: #fff;
  --background-navcolor-dark: #242424;

  --theme-image-filter: hue-rotate(0deg) brightness(0.9);
}
